/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Email } from './Email';
import {
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';

/**
 * 
 * @export
 * @interface EmailSearchResponse
 */
export interface EmailSearchResponse {
    /**
     * 
     * @type {Array<Email>}
     * @memberof EmailSearchResponse
     */
    data?: Array<Email> | null;
    /**
     * 
     * @type {number}
     * @memberof EmailSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the EmailSearchResponse interface.
 */
export function instanceOfEmailSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailSearchResponseFromJSON(json: any): EmailSearchResponse {
    return EmailSearchResponseFromJSONTyped(json, false);
}

export function EmailSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(EmailFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function EmailSearchResponseToJSON(value?: EmailSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(EmailToJSON)),
        'count': value.count,
    };
}

