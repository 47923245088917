/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Dealer } from './Dealer';
import {
    DealerFromJSON,
    DealerFromJSONTyped,
    DealerToJSON,
} from './Dealer';

/**
 * 
 * @export
 * @interface MeResponse
 */
export interface MeResponse {
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    dailyAuctionNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    continuouslyAuctionNotifications?: boolean;
    /**
     * 
     * @type {Array<Dealer>}
     * @memberof MeResponse
     */
    dealers?: Array<Dealer> | null;
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    alias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    organizationId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    auctionNotificationFavoriteOnBid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    auctionNotificationFavoriteOnWon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    auctionNotificationFavoriteExpireFirstWarning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    auctionNotificationFavoriteExpireFinalWarning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponse
     */
    isExcludedFromSmartSalg?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeResponse
     */
    excludedFromSmartSalgMessage?: string | null;
}

/**
 * Check if a given object implements the MeResponse interface.
 */
export function instanceOfMeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MeResponseFromJSON(json: any): MeResponse {
    return MeResponseFromJSONTyped(json, false);
}

export function MeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'dailyAuctionNotifications': !exists(json, 'dailyAuctionNotifications') ? undefined : json['dailyAuctionNotifications'],
        'continuouslyAuctionNotifications': !exists(json, 'continuouslyAuctionNotifications') ? undefined : json['continuouslyAuctionNotifications'],
        'dealers': !exists(json, 'dealers') ? undefined : (json['dealers'] === null ? null : (json['dealers'] as Array<any>).map(DealerFromJSON)),
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'auctionNotificationFavoriteOnBid': !exists(json, 'auctionNotificationFavoriteOnBid') ? undefined : json['auctionNotificationFavoriteOnBid'],
        'auctionNotificationFavoriteOnWon': !exists(json, 'auctionNotificationFavoriteOnWon') ? undefined : json['auctionNotificationFavoriteOnWon'],
        'auctionNotificationFavoriteExpireFirstWarning': !exists(json, 'auctionNotificationFavoriteExpireFirstWarning') ? undefined : json['auctionNotificationFavoriteExpireFirstWarning'],
        'auctionNotificationFavoriteExpireFinalWarning': !exists(json, 'auctionNotificationFavoriteExpireFinalWarning') ? undefined : json['auctionNotificationFavoriteExpireFinalWarning'],
        'isExcludedFromSmartSalg': !exists(json, 'isExcludedFromSmartSalg') ? undefined : json['isExcludedFromSmartSalg'],
        'excludedFromSmartSalgMessage': !exists(json, 'excludedFromSmartSalgMessage') ? undefined : json['excludedFromSmartSalgMessage'],
    };
}

export function MeResponseToJSON(value?: MeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'isAdmin': value.isAdmin,
        'dailyAuctionNotifications': value.dailyAuctionNotifications,
        'continuouslyAuctionNotifications': value.continuouslyAuctionNotifications,
        'dealers': value.dealers === undefined ? undefined : (value.dealers === null ? null : (value.dealers as Array<any>).map(DealerToJSON)),
        'alias': value.alias,
        'organizationId': value.organizationId,
        'auctionNotificationFavoriteOnBid': value.auctionNotificationFavoriteOnBid,
        'auctionNotificationFavoriteOnWon': value.auctionNotificationFavoriteOnWon,
        'auctionNotificationFavoriteExpireFirstWarning': value.auctionNotificationFavoriteExpireFirstWarning,
        'auctionNotificationFavoriteExpireFinalWarning': value.auctionNotificationFavoriteExpireFinalWarning,
        'isExcludedFromSmartSalg': value.isExcludedFromSmartSalg,
        'excludedFromSmartSalgMessage': value.excludedFromSmartSalgMessage,
    };
}

