/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { AuctionStatus } from './AuctionStatus';
import {
    AuctionStatusFromJSON,
    AuctionStatusFromJSONTyped,
    AuctionStatusToJSON,
} from './AuctionStatus';
import type { UserBid } from './UserBid';
import {
    UserBidFromJSON,
    UserBidFromJSONTyped,
    UserBidToJSON,
} from './UserBid';

/**
 * 
 * @export
 * @interface AuctionKeyData
 */
export interface AuctionKeyData {
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    auctionId?: string;
    /**
     * 
     * @type {number}
     * @memberof AuctionKeyData
     */
    auctionUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    registrationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    variant?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionKeyData
     */
    firstRegistrationAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionKeyData
     */
    mileage?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    propellant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    transmission?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    driveTrain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    city?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionKeyData
     */
    zipCode?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionKeyData
     */
    priceExpectation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionKeyData
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuctionKeyData
     */
    expireAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuctionKeyData
     */
    inactiveAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AuctionKeyData
     */
    bidCountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionKeyData
     */
    year?: number;
    /**
     * 
     * @type {AuctionStatus}
     * @memberof AuctionKeyData
     */
    auctionStatus?: AuctionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionKeyData
     */
    hasWinner?: boolean;
    /**
     * 
     * @type {Attachment}
     * @memberof AuctionKeyData
     */
    attachment?: Attachment;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionKeyData
     */
    vat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionKeyData
     */
    regTax?: boolean | null;
    /**
     * 
     * @type {UserBid}
     * @memberof AuctionKeyData
     */
    currentUserBid?: UserBid;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionKeyData
     */
    isFavorite?: boolean | null;
}

/**
 * Check if a given object implements the AuctionKeyData interface.
 */
export function instanceOfAuctionKeyData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuctionKeyDataFromJSON(json: any): AuctionKeyData {
    return AuctionKeyDataFromJSONTyped(json, false);
}

export function AuctionKeyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionKeyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auctionId': !exists(json, 'auctionId') ? undefined : json['auctionId'],
        'auctionUserId': !exists(json, 'auctionUserId') ? undefined : json['auctionUserId'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'firstRegistrationAt': !exists(json, 'firstRegistrationAt') ? undefined : (json['firstRegistrationAt'] === null ? null : new Date(json['firstRegistrationAt'])),
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'propellant': !exists(json, 'propellant') ? undefined : json['propellant'],
        'transmission': !exists(json, 'transmission') ? undefined : json['transmission'],
        'driveTrain': !exists(json, 'driveTrain') ? undefined : json['driveTrain'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'priceExpectation': !exists(json, 'priceExpectation') ? undefined : json['priceExpectation'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'expireAt': !exists(json, 'expireAt') ? undefined : (json['expireAt'] === null ? null : new Date(json['expireAt'])),
        'inactiveAt': !exists(json, 'inactiveAt') ? undefined : (json['inactiveAt'] === null ? null : new Date(json['inactiveAt'])),
        'bidCountTotal': !exists(json, 'bidCountTotal') ? undefined : json['bidCountTotal'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'auctionStatus': !exists(json, 'auctionStatus') ? undefined : AuctionStatusFromJSON(json['auctionStatus']),
        'hasWinner': !exists(json, 'hasWinner') ? undefined : json['hasWinner'],
        'attachment': !exists(json, 'attachment') ? undefined : AttachmentFromJSON(json['attachment']),
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'regTax': !exists(json, 'regTax') ? undefined : json['regTax'],
        'currentUserBid': !exists(json, 'currentUserBid') ? undefined : UserBidFromJSON(json['currentUserBid']),
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
    };
}

export function AuctionKeyDataToJSON(value?: AuctionKeyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auctionId': value.auctionId,
        'auctionUserId': value.auctionUserId,
        'vin': value.vin,
        'registrationNumber': value.registrationNumber,
        'make': value.make,
        'model': value.model,
        'variant': value.variant,
        'firstRegistrationAt': value.firstRegistrationAt === undefined ? undefined : (value.firstRegistrationAt === null ? null : value.firstRegistrationAt.toISOString()),
        'mileage': value.mileage,
        'propellant': value.propellant,
        'transmission': value.transmission,
        'driveTrain': value.driveTrain,
        'city': value.city,
        'zipCode': value.zipCode,
        'priceExpectation': value.priceExpectation,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'expireAt': value.expireAt === undefined ? undefined : (value.expireAt === null ? null : value.expireAt.toISOString()),
        'inactiveAt': value.inactiveAt === undefined ? undefined : (value.inactiveAt === null ? null : value.inactiveAt.toISOString()),
        'bidCountTotal': value.bidCountTotal,
        'year': value.year,
        'auctionStatus': AuctionStatusToJSON(value.auctionStatus),
        'hasWinner': value.hasWinner,
        'attachment': AttachmentToJSON(value.attachment),
        'vat': value.vat,
        'regTax': value.regTax,
        'currentUserBid': UserBidToJSON(value.currentUserBid),
        'isFavorite': value.isFavorite,
    };
}

