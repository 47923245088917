/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyAnswer
 */
export interface SurveyAnswer {
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    answer?: string | null;
}

/**
 * Check if a given object implements the SurveyAnswer interface.
 */
export function instanceOfSurveyAnswer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SurveyAnswerFromJSON(json: any): SurveyAnswer {
    return SurveyAnswerFromJSONTyped(json, false);
}

export function SurveyAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'question': !exists(json, 'question') ? undefined : json['question'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function SurveyAnswerToJSON(value?: SurveyAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question': value.question,
        'answer': value.answer,
    };
}

