/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClassifiedStatus = {
    Temporary: 'Temporary',
    Limbo: 'Limbo',
    Active: 'Active',
    Inactive: 'Inactive',
    Expired: 'Expired',
    RemovedByAdmin: 'RemovedByAdmin',
    Sold: 'Sold',
    Deleted: 'Deleted',
    InternalUse: 'InternalUse'
} as const;
export type ClassifiedStatus = typeof ClassifiedStatus[keyof typeof ClassifiedStatus];


export function ClassifiedStatusFromJSON(json: any): ClassifiedStatus {
    return ClassifiedStatusFromJSONTyped(json, false);
}

export function ClassifiedStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedStatus {
    return json as ClassifiedStatus;
}

export function ClassifiedStatusToJSON(value?: ClassifiedStatus | null): any {
    return value as any;
}

