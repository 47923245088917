/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuctionDetailData } from './AuctionDetailData';
import {
    AuctionDetailDataFromJSON,
    AuctionDetailDataFromJSONTyped,
    AuctionDetailDataToJSON,
} from './AuctionDetailData';

/**
 * 
 * @export
 * @interface BidAuctionResponse
 */
export interface BidAuctionResponse {
    /**
     * 
     * @type {AuctionDetailData}
     * @memberof BidAuctionResponse
     */
    auction?: AuctionDetailData;
}

/**
 * Check if a given object implements the BidAuctionResponse interface.
 */
export function instanceOfBidAuctionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BidAuctionResponseFromJSON(json: any): BidAuctionResponse {
    return BidAuctionResponseFromJSONTyped(json, false);
}

export function BidAuctionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidAuctionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auction': !exists(json, 'auction') ? undefined : AuctionDetailDataFromJSON(json['auction']),
    };
}

export function BidAuctionResponseToJSON(value?: BidAuctionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auction': AuctionDetailDataToJSON(value.auction),
    };
}

