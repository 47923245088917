import { useMemo } from 'react';
import {
  AuthApi,
  CallTrackingApi,
  ClassifiedApi,
  StatApi,
  Configuration,
  EmailApi,
  LeadApi,
  AuctionApi,
} from '../lib-api';
import useMiddleware from './use-middleware';

const basePath = process.env.NEXT_PUBLIC_SITE_API;
export const defaultApiConfiguration = new Configuration({
  basePath,
});

export const useApiConfiguration = () => {
  const { middleware, accessToken } = useMiddleware();

  const apiConfiguration = useMemo(() => {
    if (accessToken) {
      return new Configuration({
        basePath: basePath,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        middleware: [middleware],
      });
    }
    return defaultApiConfiguration;
  }, [accessToken, middleware]);
  return { apiConfiguration };
};

export const useApi = () => {
  const { apiConfiguration } = useApiConfiguration();

  const api = useMemo(() => {
    return {
      authApi: new AuthApi(apiConfiguration),
      classifiedsStatsApi: new StatApi(apiConfiguration),
      emailApi: new EmailApi(apiConfiguration),
      callTrackingApi: new CallTrackingApi(apiConfiguration),
      classifiedsApi: new ClassifiedApi(apiConfiguration),
      leadApi: new LeadApi(apiConfiguration),
      auctionApi: new AuctionApi(apiConfiguration),
    };
  }, [apiConfiguration]);
  return api;
};
