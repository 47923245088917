/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuctionEvent } from './AuctionEvent';
import {
    AuctionEventFromJSON,
    AuctionEventFromJSONTyped,
    AuctionEventToJSON,
} from './AuctionEvent';

/**
 * 
 * @export
 * @interface AuctionEventsResponse
 */
export interface AuctionEventsResponse {
    /**
     * 
     * @type {Array<AuctionEvent>}
     * @memberof AuctionEventsResponse
     */
    auctionEvents?: Array<AuctionEvent> | null;
}

/**
 * Check if a given object implements the AuctionEventsResponse interface.
 */
export function instanceOfAuctionEventsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuctionEventsResponseFromJSON(json: any): AuctionEventsResponse {
    return AuctionEventsResponseFromJSONTyped(json, false);
}

export function AuctionEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionEventsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auctionEvents': !exists(json, 'auctionEvents') ? undefined : (json['auctionEvents'] === null ? null : (json['auctionEvents'] as Array<any>).map(AuctionEventFromJSON)),
    };
}

export function AuctionEventsResponseToJSON(value?: AuctionEventsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auctionEvents': value.auctionEvents === undefined ? undefined : (value.auctionEvents === null ? null : (value.auctionEvents as Array<any>).map(AuctionEventToJSON)),
    };
}

