/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyAnswer } from './SurveyAnswer';
import {
    SurveyAnswerFromJSON,
    SurveyAnswerFromJSONTyped,
    SurveyAnswerToJSON,
} from './SurveyAnswer';

/**
 * 
 * @export
 * @interface SurveyResponse
 */
export interface SurveyResponse {
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    createdAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyResponse
     */
    bought?: boolean | null;
    /**
     * 
     * @type {Array<SurveyAnswer>}
     * @memberof SurveyResponse
     */
    answers?: Array<SurveyAnswer> | null;
}

/**
 * Check if a given object implements the SurveyResponse interface.
 */
export function instanceOfSurveyResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SurveyResponseFromJSON(json: any): SurveyResponse {
    return SurveyResponseFromJSONTyped(json, false);
}

export function SurveyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'bought': !exists(json, 'bought') ? undefined : json['bought'],
        'answers': !exists(json, 'answers') ? undefined : (json['answers'] === null ? null : (json['answers'] as Array<any>).map(SurveyAnswerFromJSON)),
    };
}

export function SurveyResponseToJSON(value?: SurveyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'createdAt': value.createdAt,
        'bought': value.bought,
        'answers': value.answers === undefined ? undefined : (value.answers === null ? null : (value.answers as Array<any>).map(SurveyAnswerToJSON)),
    };
}

