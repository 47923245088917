/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClassifiedFavoriteItem } from './ClassifiedFavoriteItem';
import {
    ClassifiedFavoriteItemFromJSON,
    ClassifiedFavoriteItemFromJSONTyped,
    ClassifiedFavoriteItemToJSON,
} from './ClassifiedFavoriteItem';

/**
 * 
 * @export
 * @interface ClassifiedFavoriteItemSearchResponse
 */
export interface ClassifiedFavoriteItemSearchResponse {
    /**
     * 
     * @type {Array<ClassifiedFavoriteItem>}
     * @memberof ClassifiedFavoriteItemSearchResponse
     */
    data?: Array<ClassifiedFavoriteItem> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedFavoriteItemSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the ClassifiedFavoriteItemSearchResponse interface.
 */
export function instanceOfClassifiedFavoriteItemSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassifiedFavoriteItemSearchResponseFromJSON(json: any): ClassifiedFavoriteItemSearchResponse {
    return ClassifiedFavoriteItemSearchResponseFromJSONTyped(json, false);
}

export function ClassifiedFavoriteItemSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedFavoriteItemSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ClassifiedFavoriteItemFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ClassifiedFavoriteItemSearchResponseToJSON(value?: ClassifiedFavoriteItemSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ClassifiedFavoriteItemToJSON)),
        'count': value.count,
    };
}

