/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KeyAmount = {
    One: 'One',
    TwoOreMore: 'TwoOreMore'
} as const;
export type KeyAmount = typeof KeyAmount[keyof typeof KeyAmount];


export function KeyAmountFromJSON(json: any): KeyAmount {
    return KeyAmountFromJSONTyped(json, false);
}

export function KeyAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyAmount {
    return json as KeyAmount;
}

export function KeyAmountToJSON(value?: KeyAmount | null): any {
    return value as any;
}

