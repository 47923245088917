/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidStatus } from './BidStatus';
import {
    BidStatusFromJSON,
    BidStatusFromJSONTyped,
    BidStatusToJSON,
} from './BidStatus';

/**
 * 
 * @export
 * @interface UserBid
 */
export interface UserBid {
    /**
     * 
     * @type {BidStatus}
     * @memberof UserBid
     */
    status?: BidStatus;
    /**
     * 
     * @type {number}
     * @memberof UserBid
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserBid
     */
    isHighestBid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserBid
     */
    bidCountTotal?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserBid
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserBid
     */
    statusChangeDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserBid
     */
    expireDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserBid
     */
    readonly wasHighestBid?: boolean;
}

/**
 * Check if a given object implements the UserBid interface.
 */
export function instanceOfUserBid(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserBidFromJSON(json: any): UserBid {
    return UserBidFromJSONTyped(json, false);
}

export function UserBidFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : BidStatusFromJSON(json['status']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'isHighestBid': !exists(json, 'isHighestBid') ? undefined : json['isHighestBid'],
        'bidCountTotal': !exists(json, 'bidCountTotal') ? undefined : json['bidCountTotal'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'statusChangeDate': !exists(json, 'statusChangeDate') ? undefined : (json['statusChangeDate'] === null ? null : new Date(json['statusChangeDate'])),
        'expireDate': !exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'wasHighestBid': !exists(json, 'wasHighestBid') ? undefined : json['wasHighestBid'],
    };
}

export function UserBidToJSON(value?: UserBid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': BidStatusToJSON(value.status),
        'price': value.price,
        'isHighestBid': value.isHighestBid,
        'bidCountTotal': value.bidCountTotal,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'statusChangeDate': value.statusChangeDate === undefined ? undefined : (value.statusChangeDate === null ? null : value.statusChangeDate.toISOString()),
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
    };
}

