/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CombinedLead } from './CombinedLead';
import {
    CombinedLeadFromJSON,
    CombinedLeadFromJSONTyped,
    CombinedLeadToJSON,
} from './CombinedLead';

/**
 * 
 * @export
 * @interface CombinedLeadSearchResponse
 */
export interface CombinedLeadSearchResponse {
    /**
     * 
     * @type {Array<CombinedLead>}
     * @memberof CombinedLeadSearchResponse
     */
    data?: Array<CombinedLead> | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedLeadSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the CombinedLeadSearchResponse interface.
 */
export function instanceOfCombinedLeadSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CombinedLeadSearchResponseFromJSON(json: any): CombinedLeadSearchResponse {
    return CombinedLeadSearchResponseFromJSONTyped(json, false);
}

export function CombinedLeadSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombinedLeadSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(CombinedLeadFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function CombinedLeadSearchResponseToJSON(value?: CombinedLeadSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(CombinedLeadToJSON)),
        'count': value.count,
    };
}

