/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RemoteConnectionType = {
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    MotorcykelGalleri: 'MotorcykelGalleri',
    BilGalleri: 'BilGalleri',
    AutoIt: 'AutoIt',
    AutoUncle: 'AutoUncle',
    BilInfo: 'BilInfo',
    Email: 'Email',
    Mc123Mc: 'Mc123MC',
    DanBase: 'DanBase',
    Google: 'Google',
    JyskAuktion: 'JyskAuktion',
    AldCarMarket: 'ALDCarMarket',
    ApifyScraper: 'ApifyScraper',
    ApifyScraperClickProduct: 'ApifyScraperClickProduct',
    Apple: 'Apple',
    AutoItGateway: 'AutoItGateway',
    AutoItLeadImporter: 'AutoItLeadImporter'
} as const;
export type RemoteConnectionType = typeof RemoteConnectionType[keyof typeof RemoteConnectionType];


export function RemoteConnectionTypeFromJSON(json: any): RemoteConnectionType {
    return RemoteConnectionTypeFromJSONTyped(json, false);
}

export function RemoteConnectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoteConnectionType {
    return json as RemoteConnectionType;
}

export function RemoteConnectionTypeToJSON(value?: RemoteConnectionType | null): any {
    return value as any;
}

