/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuctionStatus = {
    Ongoing: 'Ongoing',
    Completed: 'Completed',
    ExtendedAndOngoing: 'ExtendedAndOngoing',
    Cancelled: 'Cancelled'
} as const;
export type AuctionStatus = typeof AuctionStatus[keyof typeof AuctionStatus];


export function AuctionStatusFromJSON(json: any): AuctionStatus {
    return AuctionStatusFromJSONTyped(json, false);
}

export function AuctionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionStatus {
    return json as AuctionStatus;
}

export function AuctionStatusToJSON(value?: AuctionStatus | null): any {
    return value as any;
}

