/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SortDir = {
    Asc: 'Asc',
    Desc: 'Desc'
} as const;
export type SortDir = typeof SortDir[keyof typeof SortDir];


export function SortDirFromJSON(json: any): SortDir {
    return SortDirFromJSONTyped(json, false);
}

export function SortDirFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortDir {
    return json as SortDir;
}

export function SortDirToJSON(value?: SortDir | null): any {
    return value as any;
}

