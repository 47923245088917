/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsAuctionFavoriteRequest
 */
export interface IsAuctionFavoriteRequest {
    /**
     * 
     * @type {string}
     * @memberof IsAuctionFavoriteRequest
     */
    auctionId?: string;
}

/**
 * Check if a given object implements the IsAuctionFavoriteRequest interface.
 */
export function instanceOfIsAuctionFavoriteRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IsAuctionFavoriteRequestFromJSON(json: any): IsAuctionFavoriteRequest {
    return IsAuctionFavoriteRequestFromJSONTyped(json, false);
}

export function IsAuctionFavoriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsAuctionFavoriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auctionId': !exists(json, 'auctionId') ? undefined : json['auctionId'],
    };
}

export function IsAuctionFavoriteRequestToJSON(value?: IsAuctionFavoriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auctionId': value.auctionId,
    };
}

