import { defaultApiConfiguration } from 'src/hooks/use-api';
import {
  Configuration,
  AuthApi as ServerAuthApi,
  PasswordLessSignInRequest,
  MeResponse,
  RegisterRequest,
  MemberResponse,
} from 'src/lib-api';

type SignInRequest = {
  email: string;
  password: string;
};

type SignInResponse = Promise<{
  accessToken: string;
  refreshToken: string;
  expirationTime: Date;
  userId?: number;
  dealerId?: number | null;
  dealerName?: string | null;
  organizationId?: string | null;
  organizationName?: string | null;
}>;

type SignUpRequest = {
  email: string;
  name: string;
  password: string;
};

//type MeResponse = Promise<User>;

class AuthApi {
  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request;

    const apiBasePath = process.env.NEXT_PUBLIC_SITE_API;
    const apiConfiguration = new Configuration({
      basePath: apiBasePath,
    });
    const authApi = new ServerAuthApi(apiConfiguration);

    return new Promise((resolve, reject) => {
      try {
        authApi
          .signin({ signInRequest: { email, password } })
          .then((response) => {
            resolve({
              accessToken: response.accessToken!,
              refreshToken: response.refreshToken!,
              expirationTime: response.expirationTime!,
              userId: response.userId,
              dealerId: response.dealerId,
              dealerName: response.dealerName,
              organizationId: response.organizationId,
              organizationName: response.organizationName,
            });
          })
          .catch(() => {
            reject(
              new Error(
                'Tjek at e-mail og password er korrekt eller kontakt din konsulent ved Bilhandel for adgang'
              )
            );
          });
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  async register(request: RegisterRequest): SignInResponse {
    const apiBasePath = process.env.NEXT_PUBLIC_SITE_API;
    const apiConfiguration = new Configuration({
      basePath: apiBasePath,
    });
    const authApi = new ServerAuthApi(apiConfiguration);

    return new Promise((resolve, reject) => {
      try {
        authApi
          .register({ registerRequest: request })
          .then((response) => {
            resolve({
              accessToken: response.accessToken!,
              refreshToken: response.refreshToken!,
              expirationTime: response.expirationTime!,
              userId: response.userId,
              dealerId: response.dealerId,
              dealerName: response.dealerName,
              organizationId: response.organizationId,
              organizationName: response.organizationName,
            });
          })
          .catch(() => {
            reject(
              new Error(
                'Tjek at e-mail og password er korrekt eller kontakt din konsulent ved Bilhandel for adgang'
              )
            );
          });
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  async passwordlessSignIn(request: PasswordLessSignInRequest): SignInResponse {
    const { token, userId, accessToOrganizationId } = request;
    const authApi = new ServerAuthApi(defaultApiConfiguration);

    return new Promise((resolve, reject) => {
      try {
        authApi
          .passwordlessSignin({
            passwordLessSignInRequest: {
              token,
              userId,
              accessToOrganizationId,
            },
          })
          .then((response) => {
            resolve({
              accessToken: response.accessToken!,
              refreshToken: response.refreshToken!,
              expirationTime: response.expirationTime!,
              userId: response.userId,
              dealerId: response.dealerId,
              dealerName: response.dealerName,
              organizationId: response.organizationId,
              organizationName: response.organizationName,
            });
          })
          .catch(() => {
            reject(new Error('Linket virker ikke'));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken: string): Promise<MeResponse> {
    const apiBasePath = process.env.NEXT_PUBLIC_SITE_API;
    const apiConfiguration = new Configuration({
      basePath: apiBasePath,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const authApi = new ServerAuthApi(apiConfiguration);

    return new Promise((resolve, reject) => {
      try {
        authApi
          .me()
          .then((response) => {
            resolve({
              id: response.id!,
              email: response.email!,
              name: response.name!,
              dealers: response.dealers,
              isAdmin: response.isAdmin,
              dailyAuctionNotifications: response.dailyAuctionNotifications!,
              continuouslyAuctionNotifications:
                response.continuouslyAuctionNotifications!,
              auctionNotificationFavoriteOnBid:
                response.auctionNotificationFavoriteOnBid,
              auctionNotificationFavoriteOnWon:
                response.auctionNotificationFavoriteOnWon,
              auctionNotificationFavoriteExpireFirstWarning:
                response.auctionNotificationFavoriteExpireFirstWarning,
              auctionNotificationFavoriteExpireFinalWarning:
                response.auctionNotificationFavoriteExpireFinalWarning,
              alias: response.alias,
              organizationId: response.organizationId,
              isExcludedFromSmartSalg: response.isExcludedFromSmartSalg,
              excludedFromSmartSalgMessage:
                response.excludedFromSmartSalgMessage,
            });
          })
          .catch(() => {
            reject(new Error('Invalid authorization token'));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  members(accessToken: string): Promise<MemberResponse[]> {
    const apiBasePath = process.env.NEXT_PUBLIC_SITE_API;
    const apiConfiguration = new Configuration({
      basePath: apiBasePath,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const authApi = new ServerAuthApi(apiConfiguration);

    return new Promise((resolve, reject) => {
      try {
        authApi
          .managers()
          .then((response) => {
            resolve(
              response.map((p) => {
                return {
                  id: p.id!,
                  email: p.email!,
                  name: p.name!,
                  dataDepartments: p.dataDepartments!,
                  isAdmin: p.isAdmin!,
                  biddingDepartment: p.biddingDepartment!,
                };
              })
            );
          })
          .catch(() => {
            reject(new Error('Invalid authorization token'));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
