import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";
import { Issuer } from "src/utils/auth";
import { Dealer, MeResponse } from "src/lib-api";
import { useAppStore } from "src/store/store";
import { gtm } from "src/libs/gtm";

const STORAGE_KEY_TOKEN = "token";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: MeResponse | null; // todo: use User (MeResponse without Dealers)
  dealers: Dealer[];
}

export enum ActionType {
  INITIALIZE = "INITIALIZE",
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    me: MeResponse | null;
    dealers: Dealer[];
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    me: MeResponse;
    dealers: Dealer[];
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action = InitializeAction | SignInAction | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  dealers: [],
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, me, dealers } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: me,
      dealers: dealers,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { me, dealers } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user: me,
      dealers: dealers,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    dealers: [],
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  register: (
    name: string,
    email: string,
    password: string,
    token: string,
    departmentIds: number[],
    managerBiddingIds: number[],
    orgId?: string
  ) => Promise<void>;
  passwordLessOrganizationSignIn: (
    token: string,
    userId: number,
    organizationId: string,
    doNotTrack: boolean
  ) => Promise<void>;
  dispatch: (value: Action) => void;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  register: () => Promise.resolve(),
  passwordLessOrganizationSignIn: () => Promise.resolve(),
  dispatch: (value: Action) => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export interface PerssistToken {
  accessToken: string;
  refreshToken: string;
  expirationTime: Date;
}

const removePerssistToken = () => {
  localStorage.removeItem(STORAGE_KEY_TOKEN);
};

export const setPerssistToken = (token: PerssistToken) => {
  localStorage.setItem(STORAGE_KEY_TOKEN, JSON.stringify(token));
};

export const getPerssistToken = (): PerssistToken | null => {
  const value = localStorage.getItem(STORAGE_KEY_TOKEN);
  if (!value) {
    return null;
  }

  try {
    const token: PerssistToken = JSON.parse(value);
    return token;
  } catch {
    return null;
  }
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectDealer } = useAppStore();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const token = getPerssistToken();

      if (token?.accessToken) {
        const me = await authApi.me(token.accessToken);
        const dealers = me.dealers ?? [];
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            me,
            dealers: dealers,
          },
        });

        dealers.forEach((d) => {
          selectDealer(d);
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            me: null,
            dealers: [],
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          me: null,
          dealers: [],
        },
      });
    }
  }, [dispatch, selectDealer]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const register = useCallback(
    async (
      name: string,
      email: string,
      password: string,
      token: string,
      departmentIds: number[],
      managerBiddingIds: number[],
      orgId?: string
    ): Promise<void> => {
      const {
        accessToken,
        refreshToken,
        expirationTime,
        userId,
        dealerId,
        dealerName,
        organizationId,
        organizationName,
      } = await authApi.register({
        name,
        email,
        password,
        token,
        accessToOrganizationId: orgId,
        departmentIds: departmentIds,
        managerBiddingIds: managerBiddingIds,
      });
      const me = await authApi.me(accessToken);
      const dealers = me.dealers ?? [];

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          me,
          dealers,
        },
      });

      setPerssistToken({ accessToken, refreshToken, expirationTime });

      dealers.forEach((d) => {
        selectDealer(d);
      });

      gtm.push({
        event: "register_email",
        email: email,
        user_id: userId,
        dealer_id: dealerId,
        dealer_name: dealerName,
        organization_id: organizationId,
        organization_name: organizationName,
      });
    },
    [dispatch, selectDealer]
  );

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const {
        accessToken,
        refreshToken,
        expirationTime,
        userId,
        dealerId,
        dealerName,
        organizationId,
        organizationName,
      } = await authApi.signIn({ email, password });
      const me = await authApi.me(accessToken);
      const dealers = me.dealers ?? [];

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          me,
          dealers,
        },
      });

      setPerssistToken({ accessToken, refreshToken, expirationTime });

      dealers.forEach((d) => {
        selectDealer(d);
      });

      gtm.push({
        event: "login_email_password",
        email: email,
        user_id: userId,
        dealer_id: dealerId,
        dealer_name: dealerName,
        organization_id: organizationId,
        organization_name: organizationName,
      });
    },
    [dispatch, selectDealer]
  );

  const passwordLessOrganizationSignIn = useCallback(
    async (
      token: string,
      userId: number,
      organizationId: string,
      doNotTrack: boolean
    ): Promise<void> => {
      const { accessToken, refreshToken, expirationTime, organizationName } =
        await authApi.passwordlessSignIn({
          token,
          userId,
          accessToOrganizationId: organizationId,
        });
      const me = await authApi.me(accessToken);
      const dealers = me.dealers ?? [];

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          me,
          dealers: me.dealers!,
        },
      });

      setPerssistToken({ accessToken, refreshToken, expirationTime });

      dealers.forEach((d) => {
        selectDealer(d);
      });

      if (!doNotTrack) {
        gtm.push({
          event: "login_password_less",
          user_id: userId,
          organization_id: organizationId,
          organization_name: organizationName,
        });
      }
    },
    [dispatch, selectDealer]
  );

  const signOut = useCallback(async (): Promise<void> => {
    removePerssistToken();
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signOut,
        register,
        passwordLessOrganizationSignIn,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
