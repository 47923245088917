/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignInResponse
 */
export interface SignInResponse {
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    refreshToken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SignInResponse
     */
    expirationTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof SignInResponse
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof SignInResponse
     */
    dealerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    dealerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInResponse
     */
    organizationName?: string | null;
}

/**
 * Check if a given object implements the SignInResponse interface.
 */
export function instanceOfSignInResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SignInResponseFromJSON(json: any): SignInResponse {
    return SignInResponseFromJSONTyped(json, false);
}

export function SignInResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'expirationTime': !exists(json, 'expirationTime') ? undefined : (new Date(json['expirationTime'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
        'dealerName': !exists(json, 'dealerName') ? undefined : json['dealerName'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
    };
}

export function SignInResponseToJSON(value?: SignInResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'expirationTime': value.expirationTime === undefined ? undefined : (value.expirationTime.toISOString()),
        'userId': value.userId,
        'dealerId': value.dealerId,
        'dealerName': value.dealerName,
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
    };
}

