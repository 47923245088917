/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchAuctionsFilters
 */
export interface SearchAuctionsFilters {
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    query?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    vehicleType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    model?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    yearFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    yearTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    mileageFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    mileageTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    zipCodeFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    zipCodeTo?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchAuctionsFilters
     */
    propellants?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    expectedPriceFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchAuctionsFilters
     */
    expectedPriceTo?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    expireAtFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchAuctionsFilters
     */
    expireAtTo?: string | null;
}

/**
 * Check if a given object implements the SearchAuctionsFilters interface.
 */
export function instanceOfSearchAuctionsFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchAuctionsFiltersFromJSON(json: any): SearchAuctionsFilters {
    return SearchAuctionsFiltersFromJSONTyped(json, false);
}

export function SearchAuctionsFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAuctionsFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': !exists(json, 'query') ? undefined : json['query'],
        'vehicleType': !exists(json, 'vehicleType') ? undefined : json['vehicleType'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'yearFrom': !exists(json, 'yearFrom') ? undefined : json['yearFrom'],
        'yearTo': !exists(json, 'yearTo') ? undefined : json['yearTo'],
        'mileageFrom': !exists(json, 'mileageFrom') ? undefined : json['mileageFrom'],
        'mileageTo': !exists(json, 'mileageTo') ? undefined : json['mileageTo'],
        'zipCodeFrom': !exists(json, 'zipCodeFrom') ? undefined : json['zipCodeFrom'],
        'zipCodeTo': !exists(json, 'zipCodeTo') ? undefined : json['zipCodeTo'],
        'propellants': !exists(json, 'propellants') ? undefined : json['propellants'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'expectedPriceFrom': !exists(json, 'expectedPriceFrom') ? undefined : json['expectedPriceFrom'],
        'expectedPriceTo': !exists(json, 'expectedPriceTo') ? undefined : json['expectedPriceTo'],
        'expireAtFrom': !exists(json, 'expireAtFrom') ? undefined : json['expireAtFrom'],
        'expireAtTo': !exists(json, 'expireAtTo') ? undefined : json['expireAtTo'],
    };
}

export function SearchAuctionsFiltersToJSON(value?: SearchAuctionsFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'vehicleType': value.vehicleType,
        'make': value.make,
        'model': value.model,
        'yearFrom': value.yearFrom,
        'yearTo': value.yearTo,
        'mileageFrom': value.mileageFrom,
        'mileageTo': value.mileageTo,
        'zipCodeFrom': value.zipCodeFrom,
        'zipCodeTo': value.zipCodeTo,
        'propellants': value.propellants,
        'status': value.status,
        'expectedPriceFrom': value.expectedPriceFrom,
        'expectedPriceTo': value.expectedPriceTo,
        'expireAtFrom': value.expireAtFrom,
        'expireAtTo': value.expireAtTo,
    };
}

