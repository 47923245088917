/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuctionMessage
 */
export interface AuctionMessage {
    /**
     * 
     * @type {Date}
     * @memberof AuctionMessage
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    auctionId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    threadId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    author?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    attachmentUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionMessage
     */
    isSenderSelf?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuctionMessage
     */
    senderId?: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionMessage
     */
    recipientId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionMessage
     */
    attachmentType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionMessage
     */
    hasRead?: boolean;
}

/**
 * Check if a given object implements the AuctionMessage interface.
 */
export function instanceOfAuctionMessage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuctionMessageFromJSON(json: any): AuctionMessage {
    return AuctionMessageFromJSONTyped(json, false);
}

export function AuctionMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'auctionId': !exists(json, 'auctionId') ? undefined : json['auctionId'],
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'attachmentUrl': !exists(json, 'attachmentUrl') ? undefined : json['attachmentUrl'],
        'isSenderSelf': !exists(json, 'isSenderSelf') ? undefined : json['isSenderSelf'],
        'senderId': !exists(json, 'senderId') ? undefined : json['senderId'],
        'recipientId': !exists(json, 'recipientId') ? undefined : json['recipientId'],
        'attachmentType': !exists(json, 'attachmentType') ? undefined : json['attachmentType'],
        'hasRead': !exists(json, 'hasRead') ? undefined : json['hasRead'],
    };
}

export function AuctionMessageToJSON(value?: AuctionMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'auctionId': value.auctionId,
        'threadId': value.threadId,
        'author': value.author,
        'message': value.message,
        'attachmentUrl': value.attachmentUrl,
        'isSenderSelf': value.isSenderSelf,
        'senderId': value.senderId,
        'recipientId': value.recipientId,
        'attachmentType': value.attachmentType,
        'hasRead': value.hasRead,
    };
}

