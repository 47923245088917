import { useState } from 'react';
import { useApi } from 'src/hooks/use-api';
import { createDealerSlice, DealerSlice } from 'src/slices/createDealersSlice';
import {
  createAuctionSlice,
  AuctionSlice,
} from 'src/slices/createAuctionSlice';
import { create } from 'zustand';

type StoreState = DealerSlice;
type AuctionStoreState = AuctionSlice;

export const useAppStore = create<StoreState>()((...a) => ({
  ...createDealerSlice(...a),
}));

const useAuctionStore = create<AuctionStoreState>()((...a) => ({
  ...createAuctionSlice(...a),
}));

export const useAuctions = () => {
  const { auctionApi } = useApi();
  const setActiveAuctionCount = useAuctionStore(
    (state) => state.setActiveAuctionCount
  );

  useState(() => {
    const load = async () => {
      var response = await auctionApi.auctionSearch({
        searchAuctionsRequest: { rowPerPage: 0, filters: { status: 'active' } },
      });
      setActiveAuctionCount(response.count!);
    };
    load();
  });

  return {
    activeAuctionCount: useAuctionStore((state) => state.activeAuctionCount),
  };
};
