/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserProfileType = {
    Private: 'Private',
    Company: 'Company'
} as const;
export type UserProfileType = typeof UserProfileType[keyof typeof UserProfileType];


export function UserProfileTypeFromJSON(json: any): UserProfileType {
    return UserProfileTypeFromJSONTyped(json, false);
}

export function UserProfileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileType {
    return json as UserProfileType;
}

export function UserProfileTypeToJSON(value?: UserProfileType | null): any {
    return value as any;
}

