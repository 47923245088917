/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SalesType = {
    Sell: 'Sell',
    Buy: 'Buy',
    Barter: 'Barter',
    Leasing: 'Leasing',
    Engros: 'Engros',
    WithoutTax: 'WithoutTax',
    Prebooking: 'Prebooking'
} as const;
export type SalesType = typeof SalesType[keyof typeof SalesType];


export function SalesTypeFromJSON(json: any): SalesType {
    return SalesTypeFromJSONTyped(json, false);
}

export function SalesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesType {
    return json as SalesType;
}

export function SalesTypeToJSON(value?: SalesType | null): any {
    return value as any;
}

