/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClassifiedSearchItem } from './ClassifiedSearchItem';
import {
    ClassifiedSearchItemFromJSON,
    ClassifiedSearchItemFromJSONTyped,
    ClassifiedSearchItemToJSON,
} from './ClassifiedSearchItem';

/**
 * 
 * @export
 * @interface ClassifiedSearchItemSearchResponse
 */
export interface ClassifiedSearchItemSearchResponse {
    /**
     * 
     * @type {Array<ClassifiedSearchItem>}
     * @memberof ClassifiedSearchItemSearchResponse
     */
    data?: Array<ClassifiedSearchItem> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItemSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the ClassifiedSearchItemSearchResponse interface.
 */
export function instanceOfClassifiedSearchItemSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassifiedSearchItemSearchResponseFromJSON(json: any): ClassifiedSearchItemSearchResponse {
    return ClassifiedSearchItemSearchResponseFromJSONTyped(json, false);
}

export function ClassifiedSearchItemSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedSearchItemSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ClassifiedSearchItemFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ClassifiedSearchItemSearchResponseToJSON(value?: ClassifiedSearchItemSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ClassifiedSearchItemToJSON)),
        'count': value.count,
    };
}

