/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyResponse } from './SurveyResponse';
import {
    SurveyResponseFromJSON,
    SurveyResponseFromJSONTyped,
    SurveyResponseToJSON,
} from './SurveyResponse';

/**
 * 
 * @export
 * @interface CallTracking
 */
export interface CallTracking {
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    duration?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    callerId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    startedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    connectedAt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    endedAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CallTracking
     */
    hasSurvey?: boolean;
    /**
     * 
     * @type {SurveyResponse}
     * @memberof CallTracking
     */
    surveyResponse?: SurveyResponse;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    classfiedHeadline?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    classfiedId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CallTracking
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CallTracking
     */
    dealerId?: number;
}

/**
 * Check if a given object implements the CallTracking interface.
 */
export function instanceOfCallTracking(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CallTrackingFromJSON(json: any): CallTracking {
    return CallTrackingFromJSONTyped(json, false);
}

export function CallTrackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallTracking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'callerId': !exists(json, 'callerId') ? undefined : json['callerId'],
        'startedAt': !exists(json, 'startedAt') ? undefined : json['startedAt'],
        'connectedAt': !exists(json, 'connectedAt') ? undefined : json['connectedAt'],
        'endedAt': !exists(json, 'endedAt') ? undefined : json['endedAt'],
        'hasSurvey': !exists(json, 'hasSurvey') ? undefined : json['hasSurvey'],
        'surveyResponse': !exists(json, 'surveyResponse') ? undefined : SurveyResponseFromJSON(json['surveyResponse']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'classfiedHeadline': !exists(json, 'classfiedHeadline') ? undefined : json['classfiedHeadline'],
        'classfiedId': !exists(json, 'classfiedId') ? undefined : json['classfiedId'],
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
    };
}

export function CallTrackingToJSON(value?: CallTracking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'duration': value.duration,
        'callerId': value.callerId,
        'startedAt': value.startedAt,
        'connectedAt': value.connectedAt,
        'endedAt': value.endedAt,
        'hasSurvey': value.hasSurvey,
        'surveyResponse': SurveyResponseToJSON(value.surveyResponse),
        'name': value.name,
        'classfiedHeadline': value.classfiedHeadline,
        'classfiedId': value.classfiedId,
        'branchName': value.branchName,
        'dealerId': value.dealerId,
    };
}

