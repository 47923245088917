/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AirconCondition = {
    Aircondition: 'Aircondition',
    Climate: 'Climate',
    None: 'None'
} as const;
export type AirconCondition = typeof AirconCondition[keyof typeof AirconCondition];


export function AirconConditionFromJSON(json: any): AirconCondition {
    return AirconConditionFromJSONTyped(json, false);
}

export function AirconConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirconCondition {
    return json as AirconCondition;
}

export function AirconConditionToJSON(value?: AirconCondition | null): any {
    return value as any;
}

