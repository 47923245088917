/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WindshieldCondition = {
    NoErrors: 'NoErrors',
    StoneThrow: 'StoneThrow',
    Cracked: 'Cracked'
} as const;
export type WindshieldCondition = typeof WindshieldCondition[keyof typeof WindshieldCondition];


export function WindshieldConditionFromJSON(json: any): WindshieldCondition {
    return WindshieldConditionFromJSONTyped(json, false);
}

export function WindshieldConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WindshieldCondition {
    return json as WindshieldCondition;
}

export function WindshieldConditionToJSON(value?: WindshieldCondition | null): any {
    return value as any;
}

