import { Dealer } from 'src/lib-api';
import { StateCreator } from 'zustand';

export interface DealerSlice {
  selectedDealers: Dealer[];
  selectDealer: (dealer: Dealer) => void;
  deselectDealer: (id: number) => void;
}

export const createDealerSlice: StateCreator<DealerSlice> = (set, get) => ({
  selectedDealers: [],
  selectDealer: (dealer: Dealer) => {
    let selectedDealers = get().selectedDealers;
    const isSelected = selectedDealers.find((p) => p.id === dealer.id);
    if (!isSelected) {
      selectedDealers.push(dealer);
    }
    set({ selectedDealers });
  },
  deselectDealer: (id: number) => {
    let selectedDealers = get().selectedDealers;
    selectedDealers = selectedDealers.filter((p) => p.id !== id);
    set({ selectedDealers });
  },
});
