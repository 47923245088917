import { Dealer } from 'src/lib-api';
import { StateCreator } from 'zustand';

export interface DealerSlice {
  selectedDealers: Dealer[];
  selectDealer: (dealer: Dealer) => void;
  deselectDealer: (id: number) => void;
}

export interface AuctionSlice {
  activeAuctionCount: number | null;
  setActiveAuctionCount: (count: number) => void;
}

export const createAuctionSlice: StateCreator<AuctionSlice> = (set, get) => ({
  activeAuctionCount: null,
  setActiveAuctionCount: (count: number) => set({ activeAuctionCount: count }),
});
