/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClassifiedItemFuelType = {
    Diesel: 'Diesel',
    Benzin: 'Benzin',
    Electric: 'Electric',
    DieselElectric: 'DieselElectric',
    BenzinElectric: 'BenzinElectric',
    Other: 'Other'
} as const;
export type ClassifiedItemFuelType = typeof ClassifiedItemFuelType[keyof typeof ClassifiedItemFuelType];


export function ClassifiedItemFuelTypeFromJSON(json: any): ClassifiedItemFuelType {
    return ClassifiedItemFuelTypeFromJSONTyped(json, false);
}

export function ClassifiedItemFuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemFuelType {
    return json as ClassifiedItemFuelType;
}

export function ClassifiedItemFuelTypeToJSON(value?: ClassifiedItemFuelType | null): any {
    return value as any;
}

