/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Email,
  EmailSearchRequest,
  EmailSearchResponse,
} from '../models';
import {
    EmailFromJSON,
    EmailToJSON,
    EmailSearchRequestFromJSON,
    EmailSearchRequestToJSON,
    EmailSearchResponseFromJSON,
    EmailSearchResponseToJSON,
} from '../models';

export interface EmailGetRequest {
    id?: string;
}

export interface EmailSearchOperationRequest {
    emailSearchRequest?: EmailSearchRequest;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     */
    async emailGetRaw(requestParameters: EmailGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Email>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/email/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     */
    async emailGet(requestParameters: EmailGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Email> {
        const response = await this.emailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailSearchRaw(requestParameters: EmailSearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/email/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailSearchRequestToJSON(requestParameters.emailSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async emailSearch(requestParameters: EmailSearchOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailSearchResponse> {
        const response = await this.emailSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
