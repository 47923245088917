/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsAuctionFavoriteResponse
 */
export interface IsAuctionFavoriteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsAuctionFavoriteResponse
     */
    favorite?: boolean;
}

/**
 * Check if a given object implements the IsAuctionFavoriteResponse interface.
 */
export function instanceOfIsAuctionFavoriteResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IsAuctionFavoriteResponseFromJSON(json: any): IsAuctionFavoriteResponse {
    return IsAuctionFavoriteResponseFromJSONTyped(json, false);
}

export function IsAuctionFavoriteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsAuctionFavoriteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
    };
}

export function IsAuctionFavoriteResponseToJSON(value?: IsAuctionFavoriteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favorite': value.favorite,
    };
}

