/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClassifiedFavoriteItemSearchResponse,
  ClassifiedItem,
  ClassifiedSearchItemSearchResponse,
  ClassifiedSearchRequest,
  PriceChangeClassifiedSearchItemSearchResponse,
  SoldClassifiedSearchItemSearchResponse,
} from '../models';
import {
    ClassifiedFavoriteItemSearchResponseFromJSON,
    ClassifiedFavoriteItemSearchResponseToJSON,
    ClassifiedItemFromJSON,
    ClassifiedItemToJSON,
    ClassifiedSearchItemSearchResponseFromJSON,
    ClassifiedSearchItemSearchResponseToJSON,
    ClassifiedSearchRequestFromJSON,
    ClassifiedSearchRequestToJSON,
    PriceChangeClassifiedSearchItemSearchResponseFromJSON,
    PriceChangeClassifiedSearchItemSearchResponseToJSON,
    SoldClassifiedSearchItemSearchResponseFromJSON,
    SoldClassifiedSearchItemSearchResponseToJSON,
} from '../models';

export interface ClaimFavoriteRequest {
    id?: number;
}

export interface ClassifiedRequest {
    classifiedId?: number;
}

export interface FavoritesRequest {
    classifiedId?: number;
}

export interface SearchActiveRequest {
    classifiedSearchRequest?: ClassifiedSearchRequest;
}

export interface SearchPriceChangesRequest {
    classifiedSearchRequest?: ClassifiedSearchRequest;
}

export interface SearchSoldRequest {
    classifiedSearchRequest?: ClassifiedSearchRequest;
}

/**
 * 
 */
export class ClassifiedApi extends runtime.BaseAPI {

    /**
     */
    async claimFavoriteRaw(requestParameters: ClaimFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/claim-favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async claimFavorite(requestParameters: ClaimFavoriteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.claimFavoriteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classifiedRaw(requestParameters: ClassifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClassifiedItem>> {
        const queryParameters: any = {};

        if (requestParameters.classifiedId !== undefined) {
            queryParameters['classifiedId'] = requestParameters.classifiedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/classified`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedItemFromJSON(jsonValue));
    }

    /**
     */
    async classified(requestParameters: ClassifiedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClassifiedItem> {
        const response = await this.classifiedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async favoritesRaw(requestParameters: FavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClassifiedFavoriteItemSearchResponse>> {
        const queryParameters: any = {};

        if (requestParameters.classifiedId !== undefined) {
            queryParameters['classifiedId'] = requestParameters.classifiedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/favorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedFavoriteItemSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async favorites(requestParameters: FavoritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClassifiedFavoriteItemSearchResponse> {
        const response = await this.favoritesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchActiveRaw(requestParameters: SearchActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClassifiedSearchItemSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/search-active`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassifiedSearchRequestToJSON(requestParameters.classifiedSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedSearchItemSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchActive(requestParameters: SearchActiveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClassifiedSearchItemSearchResponse> {
        const response = await this.searchActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchPriceChangesRaw(requestParameters: SearchPriceChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PriceChangeClassifiedSearchItemSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/search-price-changes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassifiedSearchRequestToJSON(requestParameters.classifiedSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceChangeClassifiedSearchItemSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchPriceChanges(requestParameters: SearchPriceChangesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PriceChangeClassifiedSearchItemSearchResponse> {
        const response = await this.searchPriceChangesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchSoldRaw(requestParameters: SearchSoldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SoldClassifiedSearchItemSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/classified/search-sold`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassifiedSearchRequestToJSON(requestParameters.classifiedSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoldClassifiedSearchItemSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchSold(requestParameters: SearchSoldRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SoldClassifiedSearchItemSearchResponse> {
        const response = await this.searchSoldRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
