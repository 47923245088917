/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtraInfo
 */
export interface ExtraInfo {
    /**
     * 
     * @type {Date}
     * @memberof ExtraInfo
     */
    time?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtraInfo
     */
    message?: string | null;
}

/**
 * Check if a given object implements the ExtraInfo interface.
 */
export function instanceOfExtraInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtraInfoFromJSON(json: any): ExtraInfo {
    return ExtraInfoFromJSONTyped(json, false);
}

export function ExtraInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ExtraInfoToJSON(value?: ExtraInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'message': value.message,
    };
}

