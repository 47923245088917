/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatingAverageResponse
 */
export interface RatingAverageResponse {
    /**
     * 
     * @type {number}
     * @memberof RatingAverageResponse
     */
    average?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RatingAverageResponse
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof RatingAverageResponse
     */
    maxRating?: number;
}

/**
 * Check if a given object implements the RatingAverageResponse interface.
 */
export function instanceOfRatingAverageResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RatingAverageResponseFromJSON(json: any): RatingAverageResponse {
    return RatingAverageResponseFromJSONTyped(json, false);
}

export function RatingAverageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingAverageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'average': !exists(json, 'average') ? undefined : json['average'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'maxRating': !exists(json, 'maxRating') ? undefined : json['maxRating'],
    };
}

export function RatingAverageResponseToJSON(value?: RatingAverageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'average': value.average,
        'count': value.count,
        'maxRating': value.maxRating,
    };
}

