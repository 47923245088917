/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuctionDebsResponse
 */
export interface AuctionDebsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuctionDebsResponse
     */
    bankruptcy?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuctionDebsResponse
     */
    amount?: number;
}

/**
 * Check if a given object implements the AuctionDebsResponse interface.
 */
export function instanceOfAuctionDebsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuctionDebsResponseFromJSON(json: any): AuctionDebsResponse {
    return AuctionDebsResponseFromJSONTyped(json, false);
}

export function AuctionDebsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionDebsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankruptcy': !exists(json, 'bankruptcy') ? undefined : json['bankruptcy'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function AuctionDebsResponseToJSON(value?: AuctionDebsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankruptcy': value.bankruptcy,
        'amount': value.amount,
    };
}

