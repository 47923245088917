/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TireCondition = {
    AsNew: 'AsNew',
    Good: 'Good',
    Bad: 'Bad',
    None: 'None'
} as const;
export type TireCondition = typeof TireCondition[keyof typeof TireCondition];


export function TireConditionFromJSON(json: any): TireCondition {
    return TireConditionFromJSONTyped(json, false);
}

export function TireConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TireCondition {
    return json as TireCondition;
}

export function TireConditionToJSON(value?: TireCondition | null): any {
    return value as any;
}

