/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallTracking } from './CallTracking';
import {
    CallTrackingFromJSON,
    CallTrackingFromJSONTyped,
    CallTrackingToJSON,
} from './CallTracking';

/**
 * 
 * @export
 * @interface CallTrackingSearchResponse
 */
export interface CallTrackingSearchResponse {
    /**
     * 
     * @type {Array<CallTracking>}
     * @memberof CallTrackingSearchResponse
     */
    data?: Array<CallTracking> | null;
    /**
     * 
     * @type {number}
     * @memberof CallTrackingSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the CallTrackingSearchResponse interface.
 */
export function instanceOfCallTrackingSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CallTrackingSearchResponseFromJSON(json: any): CallTrackingSearchResponse {
    return CallTrackingSearchResponseFromJSONTyped(json, false);
}

export function CallTrackingSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallTrackingSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(CallTrackingFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function CallTrackingSearchResponseToJSON(value?: CallTrackingSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(CallTrackingToJSON)),
        'count': value.count,
    };
}

