/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimingBeltChanged = {
    Yes: 'Yes',
    No: 'No',
    DontKnow: 'DontKnow',
    DoesntHave: 'DoesntHave'
} as const;
export type TimingBeltChanged = typeof TimingBeltChanged[keyof typeof TimingBeltChanged];


export function TimingBeltChangedFromJSON(json: any): TimingBeltChanged {
    return TimingBeltChangedFromJSONTyped(json, false);
}

export function TimingBeltChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimingBeltChanged {
    return json as TimingBeltChanged;
}

export function TimingBeltChangedToJSON(value?: TimingBeltChanged | null): any {
    return value as any;
}

