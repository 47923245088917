/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PriceChangeClassifiedSearchItem } from './PriceChangeClassifiedSearchItem';
import {
    PriceChangeClassifiedSearchItemFromJSON,
    PriceChangeClassifiedSearchItemFromJSONTyped,
    PriceChangeClassifiedSearchItemToJSON,
} from './PriceChangeClassifiedSearchItem';

/**
 * 
 * @export
 * @interface PriceChangeClassifiedSearchItemSearchResponse
 */
export interface PriceChangeClassifiedSearchItemSearchResponse {
    /**
     * 
     * @type {Array<PriceChangeClassifiedSearchItem>}
     * @memberof PriceChangeClassifiedSearchItemSearchResponse
     */
    data?: Array<PriceChangeClassifiedSearchItem> | null;
    /**
     * 
     * @type {number}
     * @memberof PriceChangeClassifiedSearchItemSearchResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the PriceChangeClassifiedSearchItemSearchResponse interface.
 */
export function instanceOfPriceChangeClassifiedSearchItemSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PriceChangeClassifiedSearchItemSearchResponseFromJSON(json: any): PriceChangeClassifiedSearchItemSearchResponse {
    return PriceChangeClassifiedSearchItemSearchResponseFromJSONTyped(json, false);
}

export function PriceChangeClassifiedSearchItemSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceChangeClassifiedSearchItemSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(PriceChangeClassifiedSearchItemFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function PriceChangeClassifiedSearchItemSearchResponseToJSON(value?: PriceChangeClassifiedSearchItemSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(PriceChangeClassifiedSearchItemToJSON)),
        'count': value.count,
    };
}

