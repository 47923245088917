/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassifiedSearchItem
 */
export interface ClassifiedSearchItem {
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedSearchItem
     */
    headline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedSearchItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    imageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedSearchItem
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    emailLeadCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    callTrackingLeadCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    facebookClicks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    facebookViews?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    bilhandelViews?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    favouriteCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedSearchItem
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedSearchItem
     */
    dealerId?: number;
}

/**
 * Check if a given object implements the ClassifiedSearchItem interface.
 */
export function instanceOfClassifiedSearchItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassifiedSearchItemFromJSON(json: any): ClassifiedSearchItem {
    return ClassifiedSearchItemFromJSONTyped(json, false);
}

export function ClassifiedSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'emailLeadCount': !exists(json, 'emailLeadCount') ? undefined : json['emailLeadCount'],
        'callTrackingLeadCount': !exists(json, 'callTrackingLeadCount') ? undefined : json['callTrackingLeadCount'],
        'facebookClicks': !exists(json, 'facebookClicks') ? undefined : json['facebookClicks'],
        'facebookViews': !exists(json, 'facebookViews') ? undefined : json['facebookViews'],
        'bilhandelViews': !exists(json, 'bilhandelViews') ? undefined : json['bilhandelViews'],
        'favouriteCount': !exists(json, 'favouriteCount') ? undefined : json['favouriteCount'],
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
    };
}

export function ClassifiedSearchItemToJSON(value?: ClassifiedSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'headline': value.headline,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'price': value.price,
        'imageId': value.imageId,
        'image': value.image,
        'emailLeadCount': value.emailLeadCount,
        'callTrackingLeadCount': value.callTrackingLeadCount,
        'facebookClicks': value.facebookClicks,
        'facebookViews': value.facebookViews,
        'bilhandelViews': value.bilhandelViews,
        'favouriteCount': value.favouriteCount,
        'branchName': value.branchName,
        'dealerId': value.dealerId,
    };
}

