/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ForgotPasswordRequest,
  GenerateLinkRequest,
  HasSearchAgentsResponse,
  MeResponse,
  MemberResponse,
  PasswordLessAskForTokenRequest,
  PasswordLessSignInRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignInRequest,
  SignInResponse,
} from '../models';
import {
    ForgotPasswordRequestFromJSON,
    ForgotPasswordRequestToJSON,
    GenerateLinkRequestFromJSON,
    GenerateLinkRequestToJSON,
    HasSearchAgentsResponseFromJSON,
    HasSearchAgentsResponseToJSON,
    MeResponseFromJSON,
    MeResponseToJSON,
    MemberResponseFromJSON,
    MemberResponseToJSON,
    PasswordLessAskForTokenRequestFromJSON,
    PasswordLessAskForTokenRequestToJSON,
    PasswordLessSignInRequestFromJSON,
    PasswordLessSignInRequestToJSON,
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    ResetPasswordResponseFromJSON,
    ResetPasswordResponseToJSON,
    SignInRequestFromJSON,
    SignInRequestToJSON,
    SignInResponseFromJSON,
    SignInResponseToJSON,
} from '../models';

export interface ForgotPasswordOperationRequest {
    forgotPasswordRequest?: ForgotPasswordRequest;
}

export interface GenerateOrganizationRegistrationUrlRequest {
    generateLinkRequest?: GenerateLinkRequest;
}

export interface PasswordlessAskForTokenRequest {
    passwordLessAskForTokenRequest?: PasswordLessAskForTokenRequest;
}

export interface PasswordlessSigninRequest {
    passwordLessSignInRequest?: PasswordLessSignInRequest;
}

export interface RefreshTokenRequest {
    accessToken?: string;
    refreshToken?: string;
}

export interface RegisterOperationRequest {
    registerRequest?: RegisterRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface SetAuctionNotificationFavoriteExpireFinalWarningRequest {
    enabled?: boolean;
}

export interface SetAuctionNotificationFavoriteExpireFirstWarningRequest {
    enabled?: boolean;
}

export interface SetAuctionNotificationFavoriteOnBidRequest {
    enabled?: boolean;
}

export interface SetAuctionNotificationFavoriteOnWonRequest {
    enabled?: boolean;
}

export interface SetContinuouslyAuctionNotificationsRequest {
    enabled?: boolean;
}

export interface SetReceiveFavoriteNotificationRequest {
    dealerUserId?: number;
    receiveFavoriteNotification?: boolean;
}

export interface SigninRequest {
    signInRequest?: SignInRequest;
}

export interface UpdateNotificationsRequest {
    dealerUserId?: number;
}

export interface UpdateStatNotificationsRequest {
    requestBody?: Array<number>;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.forgotPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forgotPassword(requestParameters: ForgotPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateOrganizationRegistrationUrlRaw(requestParameters: GenerateOrganizationRegistrationUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/generate-organization-registration-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateLinkRequestToJSON(requestParameters.generateLinkRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async generateOrganizationRegistrationUrl(requestParameters: GenerateOrganizationRegistrationUrlRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateOrganizationRegistrationUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async hasSearchAgentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasSearchAgentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/hassearchagents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasSearchAgentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async hasSearchAgents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasSearchAgentsResponse> {
        const response = await this.hasSearchAgentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/managers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberResponseFromJSON));
    }

    /**
     */
    async managers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberResponse>> {
        const response = await this.managersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async meRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async me(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.meRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async passwordlessAskForTokenRaw(requestParameters: PasswordlessAskForTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/passwordless-ask-for-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordLessAskForTokenRequestToJSON(requestParameters.passwordLessAskForTokenRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async passwordlessAskForToken(requestParameters: PasswordlessAskForTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.passwordlessAskForTokenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async passwordlessSigninRaw(requestParameters: PasswordlessSigninRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/passwordless-signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordLessSignInRequestToJSON(requestParameters.passwordLessSignInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     */
    async passwordlessSignin(requestParameters: PasswordlessSigninRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.passwordlessSigninRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        const queryParameters: any = {};

        if (requestParameters.accessToken !== undefined) {
            queryParameters['accessToken'] = requestParameters.accessToken;
        }

        if (requestParameters.refreshToken !== undefined) {
            queryParameters['refreshToken'] = requestParameters.refreshToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/refresh-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     */
    async refreshToken(requestParameters: RefreshTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters.registerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     */
    async register(requestParameters: RegisterOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.registerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetPasswordResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordResponseFromJSON(jsonValue));
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetPasswordResponse> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAuctionNotificationFavoriteExpireFinalWarningRaw(requestParameters: SetAuctionNotificationFavoriteExpireFinalWarningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setauctionnotificationfavoriteexpirefinalwarning`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setAuctionNotificationFavoriteExpireFinalWarning(requestParameters: SetAuctionNotificationFavoriteExpireFinalWarningRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setAuctionNotificationFavoriteExpireFinalWarningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAuctionNotificationFavoriteExpireFirstWarningRaw(requestParameters: SetAuctionNotificationFavoriteExpireFirstWarningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setauctionnotificationfavoriteexpirefirstwarning`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setAuctionNotificationFavoriteExpireFirstWarning(requestParameters: SetAuctionNotificationFavoriteExpireFirstWarningRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setAuctionNotificationFavoriteExpireFirstWarningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAuctionNotificationFavoriteOnBidRaw(requestParameters: SetAuctionNotificationFavoriteOnBidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setauctionnotificationfavoriteonbid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setAuctionNotificationFavoriteOnBid(requestParameters: SetAuctionNotificationFavoriteOnBidRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setAuctionNotificationFavoriteOnBidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAuctionNotificationFavoriteOnWonRaw(requestParameters: SetAuctionNotificationFavoriteOnWonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setauctionnotificationfavoriteonwon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setAuctionNotificationFavoriteOnWon(requestParameters: SetAuctionNotificationFavoriteOnWonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setAuctionNotificationFavoriteOnWonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setContinuouslyAuctionNotificationsRaw(requestParameters: SetContinuouslyAuctionNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setcontinuouslyauctionnotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setContinuouslyAuctionNotifications(requestParameters: SetContinuouslyAuctionNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setContinuouslyAuctionNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setReceiveFavoriteNotificationRaw(requestParameters: SetReceiveFavoriteNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.dealerUserId !== undefined) {
            queryParameters['dealerUserId'] = requestParameters.dealerUserId;
        }

        if (requestParameters.receiveFavoriteNotification !== undefined) {
            queryParameters['receiveFavoriteNotification'] = requestParameters.receiveFavoriteNotification;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/setreceivefavoritenotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async setReceiveFavoriteNotification(requestParameters: SetReceiveFavoriteNotificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.setReceiveFavoriteNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signinRaw(requestParameters: SigninRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestToJSON(requestParameters.signInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInResponseFromJSON(jsonValue));
    }

    /**
     */
    async signin(requestParameters: SigninRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInResponse> {
        const response = await this.signinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateDailyAuctionNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/updatedailyauctionnotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateDailyAuctionNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.updateDailyAuctionNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateNotificationsRaw(requestParameters: UpdateNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        if (requestParameters.dealerUserId !== undefined) {
            queryParameters['dealerUserId'] = requestParameters.dealerUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/updatenotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateNotifications(requestParameters: UpdateNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.updateNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStatNotificationsRaw(requestParameters: UpdateStatNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auth/updatestatnotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateStatNotifications(requestParameters: UpdateStatNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.updateStatNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
