/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyResponse } from './SurveyResponse';
import {
    SurveyResponseFromJSON,
    SurveyResponseFromJSONTyped,
    SurveyResponseToJSON,
} from './SurveyResponse';

/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    classfiedHeadline?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    classfiedId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    wantsTestDrive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    wantsTrade?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    wantsCall?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    desiredTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    licensePlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    mileage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    hasSurvey?: boolean;
    /**
     * 
     * @type {SurveyResponse}
     * @memberof Email
     */
    surveyResponse?: SurveyResponse;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    dealerId?: number;
}

/**
 * Check if a given object implements the Email interface.
 */
export function instanceOfEmail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'classfiedHeadline': !exists(json, 'classfiedHeadline') ? undefined : json['classfiedHeadline'],
        'classfiedId': !exists(json, 'classfiedId') ? undefined : json['classfiedId'],
        'wantsTestDrive': !exists(json, 'wantsTestDrive') ? undefined : json['wantsTestDrive'],
        'wantsTrade': !exists(json, 'wantsTrade') ? undefined : json['wantsTrade'],
        'wantsCall': !exists(json, 'wantsCall') ? undefined : json['wantsCall'],
        'desiredTime': !exists(json, 'desiredTime') ? undefined : json['desiredTime'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'hasSurvey': !exists(json, 'hasSurvey') ? undefined : json['hasSurvey'],
        'surveyResponse': !exists(json, 'surveyResponse') ? undefined : SurveyResponseFromJSON(json['surveyResponse']),
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'message': value.message,
        'name': value.name,
        'emailAddress': value.emailAddress,
        'phone': value.phone,
        'classfiedHeadline': value.classfiedHeadline,
        'classfiedId': value.classfiedId,
        'wantsTestDrive': value.wantsTestDrive,
        'wantsTrade': value.wantsTrade,
        'wantsCall': value.wantsCall,
        'desiredTime': value.desiredTime,
        'licensePlate': value.licensePlate,
        'mileage': value.mileage,
        'hasSurvey': value.hasSurvey,
        'surveyResponse': SurveyResponseToJSON(value.surveyResponse),
        'branchName': value.branchName,
        'dealerId': value.dealerId,
    };
}

