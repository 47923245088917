/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadType } from './LeadType';
import {
    LeadTypeFromJSON,
    LeadTypeFromJSONTyped,
    LeadTypeToJSON,
} from './LeadType';
import type { SurveyResponse } from './SurveyResponse';
import {
    SurveyResponseFromJSON,
    SurveyResponseFromJSONTyped,
    SurveyResponseToJSON,
} from './SurveyResponse';

/**
 * 
 * @export
 * @interface CombinedLead
 */
export interface CombinedLead {
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    id?: string | null;
    /**
     * 
     * @type {LeadType}
     * @memberof CombinedLead
     */
    type?: LeadType;
    /**
     * 
     * @type {Date}
     * @memberof CombinedLead
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    phone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CombinedLead
     */
    hasSurvey?: boolean;
    /**
     * 
     * @type {SurveyResponse}
     * @memberof CombinedLead
     */
    surveyResponse?: SurveyResponse;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    classfiedHeadline?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedLead
     */
    classfiedId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    phoneCallStatus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedLead
     */
    phoneCallDuration?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CombinedLead
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedLead
     */
    dealerId?: number;
}

/**
 * Check if a given object implements the CombinedLead interface.
 */
export function instanceOfCombinedLead(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CombinedLeadFromJSON(json: any): CombinedLead {
    return CombinedLeadFromJSONTyped(json, false);
}

export function CombinedLeadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombinedLead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : LeadTypeFromJSON(json['type']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'hasSurvey': !exists(json, 'hasSurvey') ? undefined : json['hasSurvey'],
        'surveyResponse': !exists(json, 'surveyResponse') ? undefined : SurveyResponseFromJSON(json['surveyResponse']),
        'classfiedHeadline': !exists(json, 'classfiedHeadline') ? undefined : json['classfiedHeadline'],
        'classfiedId': !exists(json, 'classfiedId') ? undefined : json['classfiedId'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'phoneCallStatus': !exists(json, 'phoneCallStatus') ? undefined : json['phoneCallStatus'],
        'phoneCallDuration': !exists(json, 'phoneCallDuration') ? undefined : json['phoneCallDuration'],
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
    };
}

export function CombinedLeadToJSON(value?: CombinedLead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': LeadTypeToJSON(value.type),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'name': value.name,
        'phone': value.phone,
        'hasSurvey': value.hasSurvey,
        'surveyResponse': SurveyResponseToJSON(value.surveyResponse),
        'classfiedHeadline': value.classfiedHeadline,
        'classfiedId': value.classfiedId,
        'emailAddress': value.emailAddress,
        'message': value.message,
        'phoneCallStatus': value.phoneCallStatus,
        'phoneCallDuration': value.phoneCallDuration,
        'branchName': value.branchName,
        'dealerId': value.dealerId,
    };
}

