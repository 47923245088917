/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAuctionMessageRequest,
  ApiErrorMessage,
  AuctionAttachmentUploadResponse,
  AuctionContactInfoResponse,
  AuctionDebsResponse,
  AuctionDetailData,
  AuctionEventsRequest,
  AuctionEventsResponse,
  AuctionKeyDataSearchResponse,
  AuctionMessagesRequest,
  AuctionMessagesResponse,
  AuctionThreadIdResponse,
  BidAuctionRequest,
  BidAuctionResponse,
  BiddingTermsAcceptedResponse,
  CalculateBidPriceRequest,
  CalculateBidPriceResponse,
  IsAuctionFavoriteRequest,
  IsAuctionFavoriteResponse,
  SearchAuctionsRequest,
  SearchThreadsRequest,
  SearchThreadsResponse,
  SetAuctionFavoriteRequest,
} from '../models';
import {
    AddAuctionMessageRequestFromJSON,
    AddAuctionMessageRequestToJSON,
    ApiErrorMessageFromJSON,
    ApiErrorMessageToJSON,
    AuctionAttachmentUploadResponseFromJSON,
    AuctionAttachmentUploadResponseToJSON,
    AuctionContactInfoResponseFromJSON,
    AuctionContactInfoResponseToJSON,
    AuctionDebsResponseFromJSON,
    AuctionDebsResponseToJSON,
    AuctionDetailDataFromJSON,
    AuctionDetailDataToJSON,
    AuctionEventsRequestFromJSON,
    AuctionEventsRequestToJSON,
    AuctionEventsResponseFromJSON,
    AuctionEventsResponseToJSON,
    AuctionKeyDataSearchResponseFromJSON,
    AuctionKeyDataSearchResponseToJSON,
    AuctionMessagesRequestFromJSON,
    AuctionMessagesRequestToJSON,
    AuctionMessagesResponseFromJSON,
    AuctionMessagesResponseToJSON,
    AuctionThreadIdResponseFromJSON,
    AuctionThreadIdResponseToJSON,
    BidAuctionRequestFromJSON,
    BidAuctionRequestToJSON,
    BidAuctionResponseFromJSON,
    BidAuctionResponseToJSON,
    BiddingTermsAcceptedResponseFromJSON,
    BiddingTermsAcceptedResponseToJSON,
    CalculateBidPriceRequestFromJSON,
    CalculateBidPriceRequestToJSON,
    CalculateBidPriceResponseFromJSON,
    CalculateBidPriceResponseToJSON,
    IsAuctionFavoriteRequestFromJSON,
    IsAuctionFavoriteRequestToJSON,
    IsAuctionFavoriteResponseFromJSON,
    IsAuctionFavoriteResponseToJSON,
    SearchAuctionsRequestFromJSON,
    SearchAuctionsRequestToJSON,
    SearchThreadsRequestFromJSON,
    SearchThreadsRequestToJSON,
    SearchThreadsResponseFromJSON,
    SearchThreadsResponseToJSON,
    SetAuctionFavoriteRequestFromJSON,
    SetAuctionFavoriteRequestToJSON,
} from '../models';

export interface AuctionAddauctionmessageRequest {
    addAuctionMessageRequest?: AddAuctionMessageRequest;
}

export interface AuctionAddauctionmessageimageRequest {
    auctionId?: string;
    file?: Blob;
}

export interface AuctionAddauctionmessagepdfRequest {
    auctionId?: string;
    file?: Blob;
}

export interface AuctionAuctioneventsRequest {
    auctionEventsRequest?: AuctionEventsRequest;
}

export interface AuctionAuctionmessagesRequest {
    auctionMessagesRequest?: AuctionMessagesRequest;
}

export interface AuctionBidRequest {
    bidAuctionRequest?: BidAuctionRequest;
}

export interface AuctionCalculateBidPriceRequest {
    calculateBidPriceRequest?: CalculateBidPriceRequest;
}

export interface AuctionContactinfoRequest {
    auctionId?: string;
}

export interface AuctionDebsRequest {
    auctionId?: string;
}

export interface AuctionGetRequest {
    auctionId?: string;
    allowDeleted?: boolean;
}

export interface AuctionGetauctionthreadidRequest {
    auctionId?: string;
}

export interface AuctionModelsRequest {
    make?: string;
}

export interface AuctionSearchRequest {
    searchAuctionsRequest?: SearchAuctionsRequest;
}

export interface AuctionSearchthreadsRequest {
    searchThreadsRequest?: SearchThreadsRequest;
}

export interface IsAuctionFavoriteOperationRequest {
    isAuctionFavoriteRequest?: IsAuctionFavoriteRequest;
}

export interface SetAuctionFavoriteOperationRequest {
    setAuctionFavoriteRequest?: SetAuctionFavoriteRequest;
}

/**
 * 
 */
export class AuctionApi extends runtime.BaseAPI {

    /**
     */
    async auctionAddauctionmessageRaw(requestParameters: AuctionAddauctionmessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/addauctionmessage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAuctionMessageRequestToJSON(requestParameters.addAuctionMessageRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async auctionAddauctionmessage(requestParameters: AuctionAddauctionmessageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.auctionAddauctionmessageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async auctionAddauctionmessageimageRaw(requestParameters: AuctionAddauctionmessageimageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionAttachmentUploadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.auctionId !== undefined) {
            formParams.append('AuctionId', requestParameters.auctionId as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/addauctionmessageimage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionAttachmentUploadResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionAddauctionmessageimage(requestParameters: AuctionAddauctionmessageimageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionAttachmentUploadResponse> {
        const response = await this.auctionAddauctionmessageimageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionAddauctionmessagepdfRaw(requestParameters: AuctionAddauctionmessagepdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionAttachmentUploadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.auctionId !== undefined) {
            formParams.append('AuctionId', requestParameters.auctionId as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/addauctionmessagepdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionAttachmentUploadResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionAddauctionmessagepdf(requestParameters: AuctionAddauctionmessagepdfRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionAttachmentUploadResponse> {
        const response = await this.auctionAddauctionmessagepdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionAuctioneventsRaw(requestParameters: AuctionAuctioneventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionEventsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/auctionevents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuctionEventsRequestToJSON(requestParameters.auctionEventsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionEventsResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionAuctionevents(requestParameters: AuctionAuctioneventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionEventsResponse> {
        const response = await this.auctionAuctioneventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionAuctionmessagesRaw(requestParameters: AuctionAuctionmessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionMessagesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/auctionmessages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuctionMessagesRequestToJSON(requestParameters.auctionMessagesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionAuctionmessages(requestParameters: AuctionAuctionmessagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionMessagesResponse> {
        const response = await this.auctionAuctionmessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionBidRaw(requestParameters: AuctionBidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BidAuctionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/bid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BidAuctionRequestToJSON(requestParameters.bidAuctionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidAuctionResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionBid(requestParameters: AuctionBidRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BidAuctionResponse> {
        const response = await this.auctionBidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionBiddingTermsAcceptRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/bidding-terms-accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async auctionBiddingTermsAccept(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.auctionBiddingTermsAcceptRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionBiddingTermsAcceptedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BiddingTermsAcceptedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/bidding-terms-accepted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BiddingTermsAcceptedResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionBiddingTermsAccepted(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BiddingTermsAcceptedResponse> {
        const response = await this.auctionBiddingTermsAcceptedRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionCalculateBidPriceRaw(requestParameters: AuctionCalculateBidPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateBidPriceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/calculate-bid-price`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateBidPriceRequestToJSON(requestParameters.calculateBidPriceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateBidPriceResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionCalculateBidPrice(requestParameters: AuctionCalculateBidPriceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateBidPriceResponse> {
        const response = await this.auctionCalculateBidPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionContactinfoRaw(requestParameters: AuctionContactinfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionContactInfoResponse>> {
        const queryParameters: any = {};

        if (requestParameters.auctionId !== undefined) {
            queryParameters['auctionId'] = requestParameters.auctionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/contactinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionContactInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionContactinfo(requestParameters: AuctionContactinfoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionContactInfoResponse> {
        const response = await this.auctionContactinfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionDebsRaw(requestParameters: AuctionDebsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionDebsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.auctionId !== undefined) {
            queryParameters['auctionId'] = requestParameters.auctionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/debs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionDebsResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionDebs(requestParameters: AuctionDebsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionDebsResponse> {
        const response = await this.auctionDebsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionGetRaw(requestParameters: AuctionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionDetailData>> {
        const queryParameters: any = {};

        if (requestParameters.auctionId !== undefined) {
            queryParameters['auctionId'] = requestParameters.auctionId;
        }

        if (requestParameters.allowDeleted !== undefined) {
            queryParameters['allowDeleted'] = requestParameters.allowDeleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionDetailDataFromJSON(jsonValue));
    }

    /**
     */
    async auctionGet(requestParameters: AuctionGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionDetailData> {
        const response = await this.auctionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionGetauctionthreadidRaw(requestParameters: AuctionGetauctionthreadidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionThreadIdResponse>> {
        const queryParameters: any = {};

        if (requestParameters.auctionId !== undefined) {
            queryParameters['auctionId'] = requestParameters.auctionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/auctionthreadid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionThreadIdResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionGetauctionthreadid(requestParameters: AuctionGetauctionthreadidRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionThreadIdResponse> {
        const response = await this.auctionGetauctionthreadidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionMakesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/makes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async auctionMakes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.auctionMakesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionModelsRaw(requestParameters: AuctionModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/models`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async auctionModels(requestParameters: AuctionModelsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.auctionModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionSearchRaw(requestParameters: AuctionSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuctionKeyDataSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAuctionsRequestToJSON(requestParameters.searchAuctionsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuctionKeyDataSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionSearch(requestParameters: AuctionSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuctionKeyDataSearchResponse> {
        const response = await this.auctionSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionSearchthreadsRaw(requestParameters: AuctionSearchthreadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchThreadsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/searchthreads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchThreadsRequestToJSON(requestParameters.searchThreadsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchThreadsResponseFromJSON(jsonValue));
    }

    /**
     */
    async auctionSearchthreads(requestParameters: AuctionSearchthreadsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchThreadsResponse> {
        const response = await this.auctionSearchthreadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auctionTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async auctionTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.auctionTestRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isAuctionFavoriteRaw(requestParameters: IsAuctionFavoriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsAuctionFavoriteResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/is-auction-favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IsAuctionFavoriteRequestToJSON(requestParameters.isAuctionFavoriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsAuctionFavoriteResponseFromJSON(jsonValue));
    }

    /**
     */
    async isAuctionFavorite(requestParameters: IsAuctionFavoriteOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsAuctionFavoriteResponse> {
        const response = await this.isAuctionFavoriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAuctionFavoriteRaw(requestParameters: SetAuctionFavoriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/auction/set-auction-favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetAuctionFavoriteRequestToJSON(requestParameters.setAuctionFavoriteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setAuctionFavorite(requestParameters: SetAuctionFavoriteOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAuctionFavoriteRaw(requestParameters, initOverrides);
    }

}
