/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ThreadKeyData } from './ThreadKeyData';
import {
    ThreadKeyDataFromJSON,
    ThreadKeyDataFromJSONTyped,
    ThreadKeyDataToJSON,
} from './ThreadKeyData';

/**
 * 
 * @export
 * @interface SearchThreadsResponse
 */
export interface SearchThreadsResponse {
    /**
     * 
     * @type {Array<ThreadKeyData>}
     * @memberof SearchThreadsResponse
     */
    data?: Array<ThreadKeyData> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchThreadsResponse
     */
    count?: number;
}

/**
 * Check if a given object implements the SearchThreadsResponse interface.
 */
export function instanceOfSearchThreadsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchThreadsResponseFromJSON(json: any): SearchThreadsResponse {
    return SearchThreadsResponseFromJSONTyped(json, false);
}

export function SearchThreadsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchThreadsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ThreadKeyDataFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SearchThreadsResponseToJSON(value?: SearchThreadsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ThreadKeyDataToJSON)),
        'count': value.count,
    };
}

