/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceBook = {
    AllCompletedAtAuthorized: 'AllCompletedAtAuthorized',
    AllCompleted: 'AllCompleted',
    PartiallyCompleted: 'PartiallyCompleted',
    NoServiceBook: 'NoServiceBook'
} as const;
export type ServiceBook = typeof ServiceBook[keyof typeof ServiceBook];


export function ServiceBookFromJSON(json: any): ServiceBook {
    return ServiceBookFromJSONTyped(json, false);
}

export function ServiceBookFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceBook {
    return json as ServiceBook;
}

export function ServiceBookToJSON(value?: ServiceBook | null): any {
    return value as any;
}

