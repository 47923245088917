/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Specification
 */
export interface Specification {
    /**
     * 
     * @type {string}
     * @memberof Specification
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Specification
     */
    formattedValue?: string | null;
}

/**
 * Check if a given object implements the Specification interface.
 */
export function instanceOfSpecification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpecificationFromJSON(json: any): Specification {
    return SpecificationFromJSONTyped(json, false);
}

export function SpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Specification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'formattedValue': !exists(json, 'formattedValue') ? undefined : json['formattedValue'],
    };
}

export function SpecificationToJSON(value?: Specification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'formattedValue': value.formattedValue,
    };
}

