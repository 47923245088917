/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuctionEventType = {
    AuctionStarted: 'AuctionStarted',
    AuctionCompleted: 'AuctionCompleted',
    AuctionBid: 'AuctionBid',
    AuctionBidRejected: 'AuctionBidRejected',
    AuctionBidAccepted: 'AuctionBidAccepted'
} as const;
export type AuctionEventType = typeof AuctionEventType[keyof typeof AuctionEventType];


export function AuctionEventTypeFromJSON(json: any): AuctionEventType {
    return AuctionEventTypeFromJSONTyped(json, false);
}

export function AuctionEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuctionEventType {
    return json as AuctionEventType;
}

export function AuctionEventTypeToJSON(value?: AuctionEventType | null): any {
    return value as any;
}

