import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Konto',
  [tokens.nav.analytics]: 'Annoncer',
  [tokens.nav.auth]: 'Konto',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Kontakt',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Leads',
  [tokens.nav.customers]: 'Kunder',
  [tokens.nav.dashboard]: 'Oversigt',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'Henvendelser',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Log ind',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Oversigt',
  [tokens.nav.pages]: 'Sider',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Priser',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
};
