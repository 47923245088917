/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BidStatus = {
    Pending: 'Pending',
    Accepted: 'Accepted',
    RejectedByUser: 'RejectedByUser',
    RejectedByOtherAcceptedOffer: 'RejectedByOtherAcceptedOffer',
    Expired: 'Expired',
    CancelledAcceptByBuyer: 'CancelledAcceptByBuyer',
    CancelledAcceptBySeller: 'CancelledAcceptBySeller'
} as const;
export type BidStatus = typeof BidStatus[keyof typeof BidStatus];


export function BidStatusFromJSON(json: any): BidStatus {
    return BidStatusFromJSONTyped(json, false);
}

export function BidStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidStatus {
    return json as BidStatus;
}

export function BidStatusToJSON(value?: BidStatus | null): any {
    return value as any;
}

