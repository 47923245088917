/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CallTracking,
  CallTrackingSearchRequest,
  CallTrackingSearchResponse,
} from '../models';
import {
    CallTrackingFromJSON,
    CallTrackingToJSON,
    CallTrackingSearchRequestFromJSON,
    CallTrackingSearchRequestToJSON,
    CallTrackingSearchResponseFromJSON,
    CallTrackingSearchResponseToJSON,
} from '../models';

export interface CallTrackingGetRequest {
    id?: number;
}

export interface CallTrackingSearchOperationRequest {
    callTrackingSearchRequest?: CallTrackingSearchRequest;
}

/**
 * 
 */
export class CallTrackingApi extends runtime.BaseAPI {

    /**
     */
    async callTrackingGetRaw(requestParameters: CallTrackingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallTracking>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/calltracking/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallTrackingFromJSON(jsonValue));
    }

    /**
     */
    async callTrackingGet(requestParameters: CallTrackingGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallTracking> {
        const response = await this.callTrackingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async callTrackingSearchRaw(requestParameters: CallTrackingSearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CallTrackingSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/calltracking/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CallTrackingSearchRequestToJSON(requestParameters.callTrackingSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CallTrackingSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async callTrackingSearch(requestParameters: CallTrackingSearchOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CallTrackingSearchResponse> {
        const response = await this.callTrackingSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
