/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LeadType = {
    Email: 'Email',
    PhoneCall: 'PhoneCall'
} as const;
export type LeadType = typeof LeadType[keyof typeof LeadType];


export function LeadTypeFromJSON(json: any): LeadType {
    return LeadTypeFromJSONTyped(json, false);
}

export function LeadTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadType {
    return json as LeadType;
}

export function LeadTypeToJSON(value?: LeadType | null): any {
    return value as any;
}

