/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculateBidPriceRequest
 */
export interface CalculateBidPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof CalculateBidPriceRequest
     */
    bid?: number;
}

/**
 * Check if a given object implements the CalculateBidPriceRequest interface.
 */
export function instanceOfCalculateBidPriceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalculateBidPriceRequestFromJSON(json: any): CalculateBidPriceRequest {
    return CalculateBidPriceRequestFromJSONTyped(json, false);
}

export function CalculateBidPriceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateBidPriceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bid': !exists(json, 'bid') ? undefined : json['bid'],
    };
}

export function CalculateBidPriceRequestToJSON(value?: CalculateBidPriceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bid': value.bid,
    };
}

